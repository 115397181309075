<template>
  <b-card>
    <error-alert
      :errors="errors"
      message="Виникла помилка при пошуку моніторингу договорів!"
    />
    <ValidationObserver v-slot="{ handleSubmit }" ref="formFilterValidation">
      <b-form>
        <b-form-row>
          <b-col md="6" lg="4" xl="3">
            <ValidationProvider
              name="період"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="date_from_to"
            >
              <b-form-group label="Зазначте період">
                <date-range-picker
                  style="width: 100%"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                  :start-date="filter.processedAtFrom.value"
                  :end-date="filter.processedAtTo.value"
                  v-on:start-date-change="changeDateFrom"
                  v-on:end-date-change="changeDateTo"
                  :min-date="
                    me.role.name === $stringConstants('ROLE_INSPECTOR')
                      ? new Date('2022-09-01')
                      : null
                  "
                  :max-date="
                    me.role.name === $stringConstants('ROLE_INSPECTOR')
                      ? new Date()
                      : null
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3" xl="2">
            <ValidationProvider
              name="тип платежу"
              :rules="{ required: true }"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="operation_type"
            >
              <b-form-group label="Тип платежу">
                <b-form-select
                  text-field="name"
                  value-field="value"
                  v-model="filter.isPayer.value"
                  :options="operation_types"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider
              name="сума операцій"
              :rules="{ required: true, regex: /(^\d{1,8}([.]\d{1,2})?)$/ }"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="operation_sum"
            >
              <b-form-group label="Сума операцій дорівнює/перевищує в грн">
                <b-form-input
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="filter.amountHaving.value"
                  trim
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider
              name="Профіль СПФМ"
              :rules="{ required: false }"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="financial_model_id"
            >
              <b-form-group label="Профіль СПФМ">
                <b-form-select
                  v-model="filter.financialModelId.value"
                  :options="meFinancialModels"
                  value-field="id"
                  text-field="name"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider> </b-col
          >.
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-button
              variant="primary"
              @click="getContractsMonitoring()"
              class="mb-2"
              :disabled="submitting"
            >
              <i class="fa fa-search"></i> Пошук
            </b-button>
          </b-col>
          <b-col class="text-right justify-content-end">
            <b-button
              class="mb-2"
              variant="primary"
              @click="exportData"
              :disabled="submittingExport || meta.total === 0"
              v-if="$auth.can($stringConstants('PERMISSION_EXPORT_MONITORING'))"
            >
              <i class="fa fa-dot-circle-o"></i> Вивантажити xlsx
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>

    <b-table
      show-empty
      :responsive="true"
      :hover="true"
      :bordered="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :fields="fields"
      :busy="submitting"
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>

      <template v-slot:cell(contract_number)="data">
        <b-link
          target="_blank"
          v-if="data.item.contract_number"
          :to="{
            name: 'ContractList',
            query: { contractNumber: data.item.contract_number },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>

      <template v-slot:cell(counterpart_name)="data">
        <b-link
          target="_blank"
          v-if="data.item.counterpart_name"
          :to="{
            name: 'CounterpartList',
            query: { fullName: data.item.counterpart_name },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>

      <template v-slot:cell(count)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query:
              filter.isPayer === 1
                ? createQuery(data.item, 'payee')
                : createQuery(data.item, 'payer'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(sum)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query:
              filter.isPayer === 1
                ? createQuery(data.item, 'payee')
                : createQuery(data.item, 'payer'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template #empty="scope">
        <div class="h4 text-center">Дані не знайдено</div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <b-pagination
          v-model="meta.page"
          prev-text="Попередня"
          next-text="Наступна"
          hide-goto-end-buttons
          :per-page="meta.per_page"
          :total-rows="meta.total"
          @change="onChangePage"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import api from "@/api/api";
import moment from "moment/moment";
import DateRangePicker from "@/components/DateRangePickerWrapper";
import { mapGetters } from "vuex";
import ErrorAlert from "@/components/ErrorAlert.vue";
import mixins from "@/mixins";
import queryFilter from "@/shared/filterQuery";
import qs from "qs";
import debounce from "debounce";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_MONITORING")))
        next();
      else next({ name: "Page404" });
    });
  },
  name: "ContractsMonitoring",
  components: { ErrorAlert, DateRangePicker },
  mixins: [mixins],
  created() {
    this.debouncedGetContractsList = debounce(this.getContractsMonitoring, 500);
  },
  data() {
    return {
      operation_types: [
        { name: "Вхідні платежі", value: 0 },
        { name: "Вихідні платежі", value: 1 },
      ],
      sortBy: "",
      sortDesc: false,
      meta: {
        total: 0,
        page: 1,
        per_page: 20,
        sort_by: "",
      },
      filter: {},
      fields: [
        {
          key: "contract_number",
          label: "Номер договору",
        },
        {
          key: "counterpart_name",
          label: "Назва контрагента",
        },
        {
          key: "number_of_operations",
          label: "Кількість операцій",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "uah_amount",
          label: "Оборот UAH за період",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-right",
        },
      ],
      items: [],
      errors: {},
      submitting: false,
      submittingExport: false,
    };
  },
  computed: {
    ...mapGetters({
      meFinancialModels: "auth/meFinancialModels",
      me: "auth/me",
    }),
    sort: function () {
      if (this.sortDesc) return "-" + this.sortBy;
      return this.sortBy;
    },
  },
  beforeMount() {
    this.filter = this.getDefaultFilter();
    this.setDefaultFilter();
  },
  methods: {
    changeCurrentPage: function () {
      this.debouncedGetContractsList();
    },
    setDefaultFilter: function () {
      const query = Object.assign({}, this.$route.query);
      if (this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")) {
        query["processedAtFrom"] = moment().startOf("day").toDate();
        query["processedAtTo"] = moment().endOf("day").toDate();
      }
      const newFilter = queryFilter.methods.getFilterFromQuery(
        query,
        this.getDefaultFilter()
      );
      this.$set(this, "filter", newFilter);
      this.changeCurrentPage();
    },
    getDefaultFilter: function () {
      return {
        processedAtFrom: {
          name: "date",
          type: "gte",
          date: true,
          value:
            this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")
              ? moment().startOf("day").toDate()
              : moment().startOf("day").toDate(),
        },
        processedAtTo: {
          name: "date",
          type: "lte",
          date: true,
          value:
            this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")
              ? moment().endOf("day").toDate()
              : moment().toDate(),
        },
        isPayer: {
          name: "is_payer",
          type: "where",
          value: 0,
        },
        financialModelId: {
          name: "financial_model_id",
          type: "where",
          value: "",
        },
        amountHaving: {
          name: "uah_amount",
          type: "havingSumGte",
          value: "10000",
        },
      };
    },
    changeDateFrom: function (v) {
      this.filter.processedAtFrom.value = v;
    },
    changeDateTo: function (v) {
      this.filter.processedAtTo.value = v;
    },
    onChangePage(page) {
      this.getContractsMonitoring(page);
    },
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.date
            ? moment(filter.value).format("YYYY-MM-DD")
            : filter.value;
        }
      });
      return {
        page: this.meta.page,
        per_page: this.meta.per_page,
        ...filters,
      };
    },
    getContractsMonitoring(page = 1) {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submitting = true;
        this.items = [];
        this.meta.page = page;
        api
          .getContractsMonitoring(this.prepareFilterParams())
          .then(({ data }) => {
            this.items = data.data;
            this.meta.total = data.meta.total;
            this.submitting = false;
          })
          .catch(({ response }) => {
            this.submitting = false;
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          });
      });
    },
    exportData: function () {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submittingExport = true;

        api
          .exportContractsMonitoring(this.prepareFilterParams())
          .then(() => {
            this.$snotify.success("Файл буде надіслано на вашу пошту");
          })
          .catch(({ response }) => {
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          })
          .finally(() => (this.submittingExport = false));
      });
    },
    createQuery(item, path) {
      const query = {};
      if (this.filter.processedAtFrom.value) {
        const processedAtFromDate = this.filter.processedAtFrom.value;
        processedAtFromDate.setHours(0, 0, 0);
        query.processedAtFrom = processedAtFromDate.toISOString();
      }

      if (this.filter.processedAtTo.value) {
        const processedAtToDate = this.filter.processedAtTo.value;
        processedAtToDate.setHours(23, 59, 59);
        query.processedAtTo = processedAtToDate.toISOString();
      }

      if (this.filter.financialModelId.value) {
        query.financialModelId = this.filter.financialModelId.value;
      }

      query.currency = "UAH";

      if (path && item.contract_number) {
        query[path + "ContractNumber"] = item.contract_number;
      }

      return query;
    },
  },
  watch: {
    sort: function () {
      this.meta.sort_by = this.sort;
      this.getContractsMonitoring();
    },
    filter: {
      deep: true,
      handler(filter) {
        const newQuery = queryFilter.methods.getQueryFromFilter({}, filter);
        if (
          qs.stringify(this.$route.query) !==
          qs.stringify(newQuery, { arrayFormat: "comma" })
        )
          this.$router.replace({ query: newQuery });
      },
    },
  },
};
</script>
